


































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { bindResources, getBindResources } from '@/api/system/PermissionApi';

@Component({
  name: 'PermissionForm'
})
export default class PermissionForm extends Vue {
  // 弹窗显隐
  @Prop({ default: false }) visible!: boolean;

  // 权限id
  @Prop({ default: '' }) permissionId!: string;

  // ref
  @Ref() readonly formRef;

  // 权限新增实体
  permissionData: any = [];

  // 权限绑定的资源列表
  tableData: any = [];

  // 表单加载状态
  formLoading: boolean = false;

  // 监听是否显示和隐藏
  @Watch('dialogVisible')
  visibleWatcher(val) {
    if (val) {
      // 加载中
      this.formLoading = true;

      // 获取资源数据
      getBindResources({ id: this.permissionId }).then((res: Record<any, any>) => {
        // 关闭加载
        this.formLoading = false;

        if (res.code === '1') {
          this.tableData = res.data;
        }
      });
    }
  }

  // 关闭弹窗
  handleCloseDialog() {
    this.$emit('success', {});
    this.updateVisible(false);
  }

  // 监听更新显示和隐藏
  updateVisible(value) {
    this.$emit('update:visible', value);
  }

  // 保存表单
  saveForm() {
    let result = bindResources({ permissionId: this.permissionId, values: this.tableData });
    result
      .then((res: Record<any, any>) => {
        if (res.code == '1') {
          this.$message.success(res.message);
          // 关闭弹窗
          this.handleCloseDialog();
          this.$emit('success', res.data);
        } else {
          this.$message.error(res.message);
        }
      })
      .finally(() => {
        // 关闭加载框
      });
  }

  // 获取显示值
  get dialogVisible() {
    return this.visible;
  }

  // 设置显示值
  set dialogVisible(newValue) {
    this.updateVisible(false);
  }
}
