var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "70%",
        height: "70%",
        "close-on-click-modal": false,
        "destroy-on-close": true,
        center: true,
        title: _vm.$t("lang_permission_addition"),
      },
      on: { close: _vm.handleCloseDialog },
    },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("lang_resource_classification"),
              align: "center",
              prop: "date",
              width: "280",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("lang_resource_details"), prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.children, function (item, itemIndex) {
                    return _c(
                      "el-checkbox",
                      {
                        key: itemIndex,
                        model: {
                          value: item.checked,
                          callback: function ($$v) {
                            _vm.$set(item, "checked", $$v)
                          },
                          expression: "item.checked",
                        },
                      },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  })
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang_cancel_")))]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveForm } },
            [_vm._v(_vm._s(_vm.$t("lang_determine_")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }